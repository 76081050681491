import React from 'react';
import {
    web3getAccount,
} from "../../web3/web3Provider";
import { Button, Image } from "react-bootstrap";
import Swal from 'sweetalert2'

const ConnectMetamask = () => {
    const getConnect = () => {
        web3getAccount().then((res) => {
            console.log(res)
            res === "0x0000000000000000000000000000000000000000"
                ? (
                    Swal.fire({
                        icon: "error",
                        title: "Please Unlock Metamask"
                    })

                )
                : (
                    Swal.fire({
                        icon: "success",
                        title: "Connect Success"
                    }).then(() => {
                        window.location.reload()
                    })
                )

        })
    }

    return (
        <>
            <Button
                variant="warning"
                className="goldenBack btnWeb3"
                style={{
                    fontFamily: "Montserrat",
                    padding: "10px 25px"
                }}
                onClick={() => getConnect()}
            >
                <Image src="./metamask.png" height="36" /> Connect Wallet
            </Button>
        </>
    )
}
export {
    ConnectMetamask
}