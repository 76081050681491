import Web3 from "web3";
import Swal from 'sweetalert2'
import { adminAddress } from '../Config/constant';
const { BlockFundX_ABI, PBX_ABI, BUSD_ABI } = require("../Abi/abi.js");

let web3 = new Web3(Web3.currentProvider);

const ethereum = window.ethereum;

const debug = false;
// BSC Testnet
if (debug === true) web3 = new Web3(Web3.currentProvider || "https://data-seed-prebsc-1-s1.binance.org:8545");
// BSC Mainnet
if (debug !== true) web3 = new Web3(Web3.givenProvider || "https://bsc-dataseed.binance.org/");



/*
* Global Variables 
*/
let WalletAccounts = "0x0000000000000000000000000000000000000000"; // set default letiable

const PBXAddress = "0x5c19C499a08E6bdc148D80b71686F2f2f33E7B63";
const PBXToken = new web3.eth.Contract(PBX_ABI, PBXAddress);

const BUSDAddress = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
const BUSDToken = new web3.eth.Contract(BUSD_ABI, BUSDAddress);

// const BlockFundX_Address = "0xd42c23989aA3dDED694BA2Ed1Cc70b26e90adb25"; // v1.00
// const BlockFundX_Address = "0x83f3A119450Bc1154360d9383BB875dF12Cc7873"; // v1.01
const BlockFundX_Address = "0xA59acea060ad3c4725356730588288ECEb6912b9"; // v1.01.1
const BlockFundX = new web3.eth.Contract(BlockFundX_ABI, BlockFundX_Address);

/*
* Function
*/
// const checkWalletInstall = () => {
//   // Check if Web3 has been injected by the browser (Mist/MetaMask).

//   if (typeof window.ethereum === 'undefined') {
//     // Use Mist/MetaMask's provider.
//     alert('Please install Metamask. from metamask.io');
//     Swal.fire({
//       icon: "warning",
//       html: "Please install Metamask. from <a href='https://metamask.io/'>metamask.io</a>",
//     }).then(() => {
//       return false;
//     })
//     return false;
//   }
// }

const web3getAccount = async () => {
  // await window.ethereum.enable();
  // if (checkWalletInstall() === false) return
  try{
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    // const accounts = await web3.eth.getAccounts(); 
    WalletAccounts = accounts[0];
  
    //console.log(`Account : %c${WalletAccounts}`, "color: #ff0000");
  
  
    // Time to reload your interface with accounts[0]!
    ethereum.on('accountsChanged', async (accounts) => {
      WalletAccounts = accounts[0];
      window.location.reload()
      //console.log(`Account : %c ${WalletAccounts}`, "color: #ff0000");
    });
  }catch(err){
    console.log("ERROR Please Unlock Metamask")
  }

  return WalletAccounts

}

const timeNows = async () => {
  let thisTimestamp = 0

  await BlockFundX.methods
    .timeNow()
    .call((err, res) => {
      thisTimestamp = res;
    })
  return thisTimestamp;
}


const chkApprove = async () => {
  let isPoolApprove = false;
  await web3getAccount().then(async (msgSender) => {
    await PBXToken.methods
      .allowance(msgSender, BlockFundX_Address)
      .call((err, res) => {
        console.log("IsApprove = " + res)
        parseFloat(web3.utils.fromWei(res)) > 0 ? isPoolApprove = true : isPoolApprove = false
      })
  })
  return isPoolApprove;
}

const chkApproveAmount = async () => {
  let isPoolApproveAmount = 0;
  await web3getAccount().then(async (msgSender) => {
    await PBXToken.methods
      .allowance(msgSender, BlockFundX_Address)
      .call((err, res) => {
        isPoolApproveAmount = parseFloat(web3.utils.fromWei(res))
      })
  })
  // console.log("My Approved = " + isPoolApproveAmount + " PBX")
  return isPoolApproveAmount;
}

const CheckBalance = async (msgSender) => {
  let pbxBalances = 0;
  await web3getAccount().then(async (msgSender) => {
    await PBXToken.methods
    .balanceOf(msgSender)
    .call((err, res) => {
      //console.log(res)
      pbxBalances = res;
    })
  })

  return pbxBalances;
}

const CheckContractBalance = async (msgSender) => {
  let pbxBalances = 0;
    await PBXToken.methods
    .balanceOf(msgSender)
    .call((err, res) => {
      //console.log(res)
      pbxBalances = res;
    })

  return pbxBalances;
}

const CheckBUSDBalance = async (msgSender) => {
  let pbxBalances = 0;
  await BUSDToken.methods
    .balanceOf(msgSender)
    .call((err, res) => {
      //console.log(res)
      pbxBalances = res;
    })
  return pbxBalances;
}

const addApprove = async () => {

  await web3getAccount().then(async (msgSender) => {
    await CheckBalance(msgSender).then( async (balances) => {
      console.log("My Balance = " + Number(web3.utils.fromWei(balances)).toLocaleString() + " PBX")
      const tx = PBXToken.methods.approve(BlockFundX_Address, balances);
      const gas = await tx.estimateGas({ from: msgSender });

      await PBXToken.methods.approve(BlockFundX_Address, balances).send({ from: msgSender, gas }, async (err, res) => {
        if (err) {
          Swal.fire({
            icon: "error",
            text: JSON.stringify(err.message)
          })
          return
        }

        let timerInterval
        Swal.fire({
          title: 'Approving...',
          html: 'Please wait about <b></b> milliseconds.',
          timer: 20000,
          timerProgressBar: true,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading()
            const b = Swal.getHtmlContainer().querySelector('b')
            timerInterval = setInterval(() => {
              b.textContent = Swal.getTimerLeft()
            }, 100)
          },
          willClose: () => {
            clearInterval(timerInterval)
          }
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            console.log('Please wait about 20 seconds')
            window.location.reload()
          }
        })

      });
    })
  })

}

/* ########################################################### */

const totalUserInfo = async (poolId, stakerId) => {
  let preMyInfo = []
  try {
      await BlockFundX.methods
        .stakersInfo(poolId, stakerId)
        .call({from: adminAddress}, (err, res) => {
          if (err) {
            // console.log(err)
            return
          }
          if(res !== undefined) preMyInfo.push(res)
        }).catch((err) => console.log(err.Error.message))
  } catch (err) {
    console.log(err.Error.message)
  }

  return preMyInfo[0]

}

const userInfo = async (poolId) => {
  let preMyInfo = []
  try {
    await web3getAccount().then(async (msgSender) => {
      await BlockFundX.methods
        .stakerInfo(poolId, msgSender)
        .call((err, res) => {
          if (err) {
            //console.log(err)
            return
          }

          preMyInfo.push(res)
          
          
          // preMyInfo.deposits = parseFloat(web3.utils.fromWei(res.deposits))
          // preMyInfo.lastHarvest = parseFloat(res.lastHarvest)
          // preMyInfo.timelocks = parseFloat(res.timelocks)
          // preMyInfo.pendingsRewards = parseFloat(web3.utils.fromWei(res.pendingsRewards))
        })
    })
  } catch (err) {
    // console.log(err)
    // console.log({ "ERROR": err.message })
  }

  return preMyInfo[0]

}

const userPendingReward = async (poolId) => {
  let preMyInfo = {
    "poolId": poolId,
    "pendingsRewards": 0
  }

  try {
    await web3getAccount().then(async (msgSender) => {
      await BlockFundX.methods
        .pendingRewards(poolId, msgSender)
        .call((err, res) => {
          if (err) {
            console.log(err)
            return
          }
          // console.log(res)
          preMyInfo.pendingsRewards = parseFloat(web3.utils.fromWei(res.pendingsRewards))
        })
    })
  } catch (err) {
    console.log(err)
  }

  return preMyInfo

}

/* Contract Info */
const totalBlockFundX = async () => {
  let preData = 0
  await BlockFundX.methods.totalBlockFundX().call((err, res) => preData = parseFloat(res))
  // console.log("totalBlockFundX = " + preData)

  return preData;

}

const blockFundXInfo = async (poolId) => {
  const blockFundXInfo = await BlockFundX.methods._BlockFundXInfo(poolId).call()
  const blockFundXState = await BlockFundX.methods._BlockFundXState(poolId).call()
  let limitStakes = parseFloat(web3.utils.fromWei(blockFundXInfo.LimitUnitStake)) / parseFloat(blockFundXInfo.PBXPerUnit)

  let data = {
    TokenReward: blockFundXInfo.TokenReward,
    TokenStake: blockFundXInfo.TokenStake,
    Balance: web3.utils.fromWei(blockFundXInfo.Balance),
    URL: blockFundXInfo.URL,
    APRpercent: blockFundXInfo.APRpercent,
    WinPercent : blockFundXState.WinPercent,
    DDpercent : blockFundXState.DDpercent,
    DaysLock: blockFundXInfo.DaysLock,
    LimitUnitStake: web3.utils.toWei(limitStakes.toString()),
    PBXPerUnit: blockFundXInfo.PBXPerUnit,
    TVL: blockFundXInfo.TVL,
    TotalHarvest: blockFundXState.TotalHarvest,
    TotalStaker: blockFundXInfo.TotalStaker,
    TurnOnRecover6percent: blockFundXInfo.TurnOnRecover6percent,
    TurnOnRecover6percentForHarvest: blockFundXInfo.TurnOnRecover6percentForHarvest,
    IsFinish: blockFundXInfo.IsFinish,
    IsHarvestOff: blockFundXInfo.IsHarvestOff
  }
  // console.log(data)
  return data;

}

const setBlockFundX = async (blockFundXId, tokenStake, tokenReward, Balances, URL, APRpercents, WinPercents, DDpercents, daysLock, PBXPerUnit, limitUnitStakes) => {
  let Balance = web3.utils.toWei(`${Balances}`)
  let APRpercent = (parseFloat(APRpercents)*1000)/10
  let WinPercent = Math.round(WinPercents*100)
  let DDpercent = DDpercents*100
  let limitUnitStake = web3.utils.toWei(`${limitUnitStakes}`)
  let txnHash = "";
console.log(APRpercent)
  // console.log(blockFundXId, tokenStake, Balance, URL, tokenReward, APRpercent, WinPercent, DDpercent, daysLock, PBXPerUnit, limitUnitStake)
  // return

  await web3getAccount().then(async (msgSender) => {
    const tx = BlockFundX.methods.editBlockFundX(blockFundXId, tokenStake, tokenReward, Balance, URL, APRpercent, WinPercent, DDpercent, daysLock, PBXPerUnit, limitUnitStake)
    const gas = await tx.estimateGas({ from: msgSender }); // await tx.estimateGas({from: _from})
    await BlockFundX.methods
      .editBlockFundX(blockFundXId, tokenStake, tokenReward, Balance, URL, APRpercent, WinPercent, DDpercent, daysLock, PBXPerUnit, limitUnitStake)
      .send({ from: msgSender, gas }, async (err, res) => {
        if (err) {
          console.log(err.message)
          Swal.fire({
            icon: "error",
            text: JSON.stringify(err.message)
          })
          return
        } else {
          txnHash = res
        }
      })
  })
  return txnHash
}


const getPBXPrice = async () => {
  let preData = ""
  await BlockFundX.methods.PBXPrice().call((err, res) => {
    preData = parseFloat(res)/10000
  })
  // console.log(preData)
  return preData;

}

/* setPBXPrice */
const setPBXPrice = async (_prices) => {

  let _price = _prices*10000
  let txnHash = "";

  await web3getAccount().then(async (msgSender) => {
    const tx = BlockFundX.methods.editPBXPrice(_price);
    const gas = await tx.estimateGas({ from: msgSender }); // await tx.estimateGas({from: _from})
    await BlockFundX.methods
      .editPBXPrice(_price)
      .send({ from: msgSender, gas }, async (err, res) => {
        if (err) {
          Swal.fire({
            icon: "error",
            text: JSON.stringify(err.message)
          })
          return
        } else {
          txnHash = res
        }
      })
  })
  return txnHash
}

const myRemainStake = async () => {
  let preData = 0
  await web3getAccount().then(async (msgSender) => {
    await BlockFundX.methods.myRemainStake(msgSender).call((err, res) => {
      if(res > 0){
        preData = parseFloat(web3.utils.fromWei(res))
      }
    })
  })
  // console.log("My Total Remain Staked = " + preData + " PBX");

  return preData;

}

const Stake = async (poolId, _amount, msgSender) => {
  let amount = web3.utils.toWei(`${_amount}`);
  let txnHash = "";

  const tx = BlockFundX.methods.deposit(0, amount);
  const gas = await tx.estimateGas({ from: msgSender }); // await tx.estimateGas({from: _from})
  await BlockFundX.methods
    .deposit(poolId, amount)
    .send({ from: msgSender, gas }, async (err, res) => {
      if (err) {
        Swal.fire({
          icon: "error",
          text: JSON.stringify(err.message)
        })
        return
      } else {
        txnHash = res
      }
    })
  return txnHash
}


const Harvest = async (poolId, msgSender) => {
  let txnHash = "";
  const tx = BlockFundX.methods.harvest(poolId);
  const gas = await tx.estimateGas({ from: msgSender }); // await tx.estimateGas({from: _from})
  await BlockFundX.methods
    .harvest(poolId)
    .send({ from: msgSender, gas }, async (err, res) => {
      if (err) {
        Swal.fire({
          icon: "error",
          text: JSON.stringify(err.message)
        })
        return
      } else {
        txnHash = res
      }
    })
  return txnHash
}

const Unstake = async (poolId, _amount, msgSender) => {
  // let amount = web3.utils.toWei(`${_amount}`);
  let amount = _amount;
  let txnHash = "";

  const tx = BlockFundX.methods.withdraw(poolId, amount);
  const gas = await tx.estimateGas({ from: msgSender }); // await tx.estimateGas({from: _from})
  await BlockFundX.methods
    .withdraw(poolId, amount)
    .send({ from: msgSender, gas }, async (err, res) => {
      if (err) {
        console.log("Error this function")
        Swal.fire({
          icon: "error",
          title: "ERROR",
          text: JSON.stringify(err.message)
        })
        return
      } else {
        txnHash = res
      }
    })
  return txnHash
}

const adminWithdraw = async (tokens, amounts) => {
  
  let amount = web3.utils.toWei(`${amounts}`);
  let txnHash = "";

  await web3getAccount().then(async (msgSender) => {
    console.log(tokens, amounts, msgSender)
    const tx = BlockFundX.methods.recoverWrongToken(tokens, amount);
    const gas = await tx.estimateGas({ from: msgSender }); // await tx.estimateGas({from: _from})
    await BlockFundX.methods
      .recoverWrongToken(tokens, amount)
      .send({ from: msgSender, gas }, async (err, res) => {
        if (err) {
          Swal.fire({
            icon: "error",
            title: "ERROR",
            text: JSON.stringify(err.message)
          })
          return
        } else {
          txnHash = res
        }
      })
  })
  return txnHash
}

export { web3, web3getAccount, timeNows, CheckBalance, CheckContractBalance, CheckBUSDBalance, chkApprove, WalletAccounts,
  PBXAddress, BUSDAddress,
  BlockFundX_Address, adminWithdraw, getPBXPrice, setPBXPrice,
  chkApproveAmount, addApprove, userInfo, userPendingReward, totalUserInfo,
  totalBlockFundX, blockFundXInfo, setBlockFundX,
  myRemainStake, Stake, Harvest, Unstake 
}