import React from 'react';
import {
    addApprove
} from "../../web3/web3Provider";
import { Card, Button } from "react-bootstrap";
import { Lock } from 'react-bootstrap-icons';

const ApprovePool = (props) => {
    return (
        <>
            <Card style={{ backgroundColor: "#eff6ff" }}>
                <Card.Body>
                    <p style={{ fontSize: "12px!important", letterSpacing: ".3em" }}>Please Approve</p>
                    <p>
                        <Button variant="warning" className="goldenBack btnWeb3 w-100" onClick={() => addApprove(parseInt(props.poolId))} >
                            <Lock />
                            Approve
                        </Button>
                    </p>

                </Card.Body>
            </Card>
        </>
    )
}
export {
    ApprovePool
}