import React, {useState, useEffect} from "react";
import {
  web3,
  web3getAccount,
  CheckBalance,
} from "../web3/web3Provider";
import './../App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Badge } from "react-bootstrap";
import { HouseDoorFill } from 'react-bootstrap-icons';


function Header() {

    const [PBXBalance, setPBXBalance] = useState({})

    const getPBXbalance = () => {
      web3getAccount().then((res) => {

        CheckBalance(res).then((result) => {
          setPBXBalance({"account":`${res.substring(0, 6)}...${res.substring(res.length-4)}`, "balance": parseFloat(web3.utils.fromWei(result)).toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") })
        })
      })
    }

    useEffect(() => {
      getPBXbalance() 
    }, [])

    return (
      
      <>
        <div className="d-flex justify-content-between" style={{ padding: ".85rem"}}>
          <div>
            <Button variant="warning" className="goldenBack btnWeb3 btn-sm" onClick={ () => window.location.href="./" }><HouseDoorFill/> <small>Home</small></Button>
          </div>
          <div>
            <Badge bg="light" text="dark">
            { PBXBalance.account }
            </Badge>{' '}
              <Badge className="goldenBack btnWeb3"> <Badge bg="light" text="dark">
                 { PBXBalance.balance }
              </Badge >{' '}
            PBX</Badge>
          </div>
        </div>
        <br />
        <br />
      </>
    );
  }

export default Header;