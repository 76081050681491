const adminAddress = "0x123358F192afE0a2128321595e7b50CB45Ca8975"

const tokens = 
    {
        BUSD:{
            img: "https://assets.trustwalletapp.com/blockchains/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
            address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
        }
    }

const blockFundXStorage = (poolId) => {

    const data = [
         {
             "profile": "http://polarbear100x.co/wp-content/uploads/2022/01/PBFI-Coin-Resized.png",
             "rewardImg": tokens.BUSD.img, 
         },
    ]
    return data[poolId];
}

const timeConverter = (UNIX_timestamp) => {
    const addZero = (i) => {
        if (i < 10) { i = "0" + i }
        return i;
    }
    var a = new Date(UNIX_timestamp*1000);
    var months = ['01', '02', '03', '04', '05', '06.', '07', '08', '09', '10', '11', '12'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var dates = () => date < 10 ? "0"+date : date
    var hour = addZero(a.getHours());
    var min = addZero(a.getMinutes());
    var sec = addZero(a.getSeconds());
    var time = dates() + '/' + month + '/' + year + ' - ' + hour + ':' + min + ':' + sec;
    return time;
}

const NumberToDecimal = (_a, _b) => {
	let a = _a
	let b = a * 10 ** _b
	let c = Math.floor(b)/10 ** _b
    return c
}

export {
    adminAddress,
    blockFundXStorage,
    timeConverter,
    NumberToDecimal
}