import React, { useState, useEffect } from "react";
import {
    web3,
    web3getAccount,
    CheckBalance,
    chkApproveAmount,
    addApprove,
    Stake,
} from "../../web3/web3Provider";
import { Modal, Button, InputGroup, Form, FormControl, Spinner } from "react-bootstrap";
import { BoxArrowInDownRight, CheckSquare } from 'react-bootstrap-icons';
import Swal from 'sweetalert2'

const ModalInvest = (props) => {
    const [amount, setAmount] = useState(0);
    const [iconStakeLoading, setIconStakeLoading] = useState(false);
    const [balance, setBalance] = useState(0)
    const [currentApproved, setCurrentApprove] = useState(0)

    const setMaxStakes = () => {
        setAmount(props.maxStake)
    }

    const callStake = async (poolsId) => {
        setIconStakeLoading(true)

        if (parseFloat(amount) <= 0) {
            Swal.fire({
                icon: 'error',
                title: 'EROR',
                text: "Amount is not null"
            }).then(() => setIconStakeLoading(false), setAmount(""))
            return
        }

        if (parseFloat(amount) > props.maxStake) {
            Swal.fire({
                icon: 'error',
                title: 'ERROR',
                text: "Amount is more than balance of you Staked PBX"
            }).then(() => setIconStakeLoading(false), setAmount(""))
            return
        }

        if (parseFloat(amount) > props.approvedPool) {
            Swal.fire({
                icon: 'warning',
                title: '<h4 class="text-danger">Please inscrease Approve</h4>',
                // text: "Amount is more than balance of you approve PBX",
                html: `
                <p>You want to invest <b class="text-dark">${amount}</b> PBX</p>
                <p>But you approval is <b class="text-danger">${props.approvedPool}</b> PBX</p>
                `,
                showCancelButton: true,
                confirmButtonText: 'Inscrease Approve',
                confirmButtonColor: '#ffc107',
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    addApprove(parseInt(props.poolId)).then(() => {
                        let timerInterval
                        Swal.fire({
                            title: 'Approving...',
                            html: 'Please wait about <b></b> milliseconds.',
                            timer: 25000,
                            timerProgressBar: true,
                            allowOutsideClick: false,
                            didOpen: () => {
                                Swal.showLoading()
                                const b = Swal.getHtmlContainer().querySelector('b')
                                timerInterval = setInterval(() => {
                                    b.textContent = Swal.getTimerLeft()
                                }, 100)
                            },
                            willClose: () => {
                                clearInterval(timerInterval)
                            }
                        }).then((result) => {
                            /* Read more about handling dismissals below */
                            if (result.dismiss === Swal.DismissReason.timer) {
                                console.log('Please wait about 25 seconds')
                                window.location.reload()
                            }
                        })
                    })
                }
            })
            setIconStakeLoading(false)
            setAmount("")
            return
        }

        try {
            await web3getAccount().then(async (res) => {
                await Stake(poolsId, amount, res).then(async (txn) => {
                    Swal.fire({
                        background: 'black',
                        width: 480,
                        timer: 10 * 1000,
                        html: `
                            <img src="img/stake.gif" width="100%">
                        `,
                        timerProgressBar: true,
                        allowOutsideClick: false,
                        showConfirmButton: false
                    }).then(() => {
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: 'Invest ' + amount + ' PBX is done',
                            footer: 'TXN : <a href="https://bscscan.com/tx/' + txn + '" target="_blank" rel="noreferrer">View Transaction</a>'
                        }).then(() => {
                            setIconStakeLoading(false)
                            window.location.reload()
                        })
                    })
                })
            })
        } catch (err) {
            console.log("ERROR บรรทัดนี้")
            console.log(err)
            Swal.fire({
                icon: 'error',
                title: 'EROR',
                text: JSON.stringify(err.message)
            }).then(() => setIconStakeLoading(false))
        }
    }


    useEffect(() => {
        const checkBalanceAndCurrentApproved = async () => {
            const balance = await CheckBalance()
            const currentApproved = await chkApproveAmount()
            setBalance(parseFloat(web3.utils.fromWei(balance)))
            setCurrentApprove(currentApproved)
        }
        checkBalanceAndCurrentApproved()
    }, [])

    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Invest PBX
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: "25px 25px 25px 25px" }}>
                    <InputGroup className="mb-3">
                        <FormControl className="bg-dark text-light text-center btnWeb3" type="number" min="1" step="0.001" value={amount} onInput={(e) => setAmount(e.target.value)} placeholder="0" aria-describedby="basic-addon1" />
                        <Button variant="warning" className="goldenBack btnWeb3" onClick={() => setMaxStakes()}>MAX</Button>
                    </InputGroup>
                    <div className="d-flex justify-content-between">
                        <span>Min 0</span>
                        <span>{props.maxStake} Max</span>
                    </div>
                    <Form.Range min={0} max={props.maxStake} step={0.001} value={amount === 0 ? 0 : amount} onChange={(e) => setAmount(e.target.value)} />
                    <div className="d-flex justify-content-around">
                        <button className="btn btn-light btn-sm text-primary btnWeb3" style={{ background: "#e3e3e3" }} onClick={() => props.maxStake > 0 ? setAmount(props.maxStake * 0.25) : 0}>25%</button>
                        <button className="btn btn-light btn-sm text-primary btnWeb3" style={{ background: "#e3e3e3" }} onClick={() => props.maxStake > 0 ? setAmount(props.maxStake * 0.5) : 0}>50%</button>
                        <button className="btn btn-light btn-sm text-primary btnWeb3" style={{ background: "#e3e3e3" }} onClick={() => props.maxStake > 0 ? setAmount(props.maxStake * 0.75) : 0}>75%</button>
                        <button className="btn btn-light btn-sm text-primary btnWeb3" style={{ background: "#e3e3e3" }} onClick={() => props.maxStake > 0 ? setAmount(props.maxStake) : 0}>100%</button>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    {currentApproved !== balance
                        ? (
                            <>
                                <Button variant="warning" className="goldenBack w-100 btnWeb3" style={{ height: "50px" }} onClick={() => addApprove(0)}>
                                    <CheckSquare />{' '}
                                    Approve New Balance
                                </Button>
                                <small className="w-100" style={{ border: '2px solid red', padding: '10px 10px 10px 10px', borderRadius: '5px' }}>
                                    <b className="text-danger">* Please [ Approve New Balance ] again because current balance not equal current approved</b>
                                    <div>- Current Balance : {balance} PBX</div>
                                    <div>- Current Approved : {currentApproved} PBX</div>
                                </small>
                            </>

                        )
                        : (
                            <Button variant="warning" className="goldenBack w-100 btnWeb3" style={{ height: "50px" }} onClick={() => callStake(props.poolId)} >
                                {iconStakeLoading === true ? (<><Spinner animation="border" variant="light" size="sm" />{" "}</>) : (<><BoxArrowInDownRight />{" "}</>)}
                                Invest
                            </Button>
                        )
                    }

                    <Button variant="secondary" className="w-100 btnWeb3" style={{ height: "50px" }} onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}


export {
    ModalInvest
}