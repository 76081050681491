/* mainnet */
const BlockFundX_ABI = require('./BlockFundX_MultiBlock-ABI.json');
const PBX_ABI = require('./PBX-ABI.json');
const BUSD_ABI = require('./BUSD-ABI.json');


module.exports = { 
    BlockFundX_ABI,
    PBX_ABI,
    BUSD_ABI
};
